<template>
	<a-modal v-model="show" title="档案" :width="800" @ok="doSubmit" @cancel="_close" centered destroyOnClose>
		<a-form-model ref="formRef" :model="formData" :rules="formRule">
			<a-row :gutter="15">
				<a-col span="6" v-if="getOrganId() == 0">
					<a-form-model-item label="所属单位" label-position="top" prop="organId">
						<a-select v-model="formData.organId" style="width:100%" placeholder="请选择单位">
							<a-select-option :value="organ.organId" v-for="(organ, index) in organList" :key="index">{{ organ.name }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="部门">
						<select-depart :value="formData.depId" @input="e => (formData.depId = e)"></select-depart>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="姓名">
						<select-staff :names="formData.name" :value="formData.staffId" @confirm="staff"></select-staff>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="性别">
						<a-select v-model="formData.sex" placeholder="请选择性别">
							<a-select-option v-for="(item, index) in sexList" :key="index" :value="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="5">
					<a-form-model-item label-position="top" label="出生日期">
						<a-date-picker type="date" placeholder="请输入出生日期" v-model="formData.birthday"></a-date-picker>
					</a-form-model-item>
				</a-col>
				<a-col span="4">
					<a-form-model-item label-position="top" label="民族">
						<a-input placeholder="请输入民族" v-model="formData.nation"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label-position="top" label="电子邮件">
						<a-input placeholder="请输入邮箱" type="mail" v-model="formData.email"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label-position="top" label="手机号">
						<a-input placeholder="请输入手机号" v-model="formData.phone"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<!-- <a-col span="6">
					<a-form-model-item label-position="top" label="照片">
						<a-input v-model="formData.photo"></a-input>
					</a-form-model-item>
				</a-col> -->
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label-position="top" label="身份证号">
						<a-input placeholder="请输入身份证号" v-model="formData.idCardNumber"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="8">
					<a-form-model-item label-position="top" label="户口所在地">
						<a-input placeholder="请输入户口所在地" v-model="formData.registerdAddress"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="3">
					<a-form-model-item label-position="top" label="婚姻状态">
						<a-select placeholder="请选择婚姻状态" v-model="formData.marriage">
							<a-select-option v-for="(item, index) in marriageList" :key="index" :value="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="4">
					<a-form-model-item label-position="top" label="政治面貌">
						<a-select v-model="formData.politicsStatus">
							<a-select-option placeholder="请选择政治面貌" v-for="(item, index) in politicsStatusList" :key="index" :value="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="8">
					<a-form-model-item label-position="top" label="籍贯">
						<a-input placeholder="请输入籍贯" v-model="formData.orgion"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label-position="top" label="家庭住址">
						<a-input placeholder="请输入家庭住址" v-model="formData.homeAddress"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label-position="top" label="毕业院校">
						<a-input placeholder="请输入毕业院校" v-model="formData.schoolName"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="4">
					<a-form-model-item label-position="top" label="学历">
						<a-select placeholder="请选择学历" v-model="formData.education">
							<a-select-option v-for="(item, index) in educationList" :key="index" :value="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="专业">
						<a-input placeholder="请输入专业" v-model="formData.specialty"></a-input>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="参加工作时间">
						<a-date-picker placeholder="请输入参加工作时间" type="date" v-model="formData.workDate"></a-date-picker>
					</a-form-model-item>
				</a-col>
				<a-col span="7">
					<a-form-model-item label-position="top" label="加入本单位时间">
						<a-date-picker placeholder="请输入加入本单位时间" type="date" v-model="formData.joinDate"></a-date-picker>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15">
				<a-col span="5">
					<a-form-model-item label-position="top" label="职称">
						<a-select placeholder="请选择职称" v-model="formData.rank">
							<a-select-option v-for="(item, index) in rankList" :key="index" :value="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="员工类型">
						<a-select placeholder="请选择员工类型" v-model="formData.staffType">
							<a-select-option v-for="(item, index) in staffTypeList" :key="index" :value="item">{{ item }}</a-select-option>
						</a-select>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="职务">
						<a-input placeholder="请输入职务" v-model="formData.jobName"></a-input>
					</a-form-model-item>
				</a-col>
			</a-row>
			<a-row :gutter="15"></a-row>
			<a-row :gutter="15">
				<a-col span="6">
					<a-form-model-item label-position="top" label="合同开始日期">
						<a-date-picker placeholder="请输入合同开始日期" type="date" v-model="formData.contractStart"></a-date-picker>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="合同截至日期">
						<a-date-picker placeholder="请输入合同截至日期" type="date" v-model="formData.contractEnd"></a-date-picker>
					</a-form-model-item>
				</a-col>
				<a-col span="6">
					<a-form-model-item label-position="top" label="转正日期">
						<a-date-picker placeholder="请输入转正日期" type="date" v-model="formData.officialDate"></a-date-picker>
					</a-form-model-item>
				</a-col>
			</a-row>
			<div style="width:100px">
				<up-img :thumb="formData.photo" @success="e => (formData.photo = e)" ref="upimg"></up-img>
			</div>
		</a-form-model>
	</a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import editor from '@/components/component/editor.vue';
import selectDepart from '@/components/common/select-depart.vue';
import selectStaff from '@/components/common/select-staff.vue';
import upImg from '@/components/common/upImg.vue';
import moment from 'moment';
export default {
	components: { editor, selectDepart, selectStaff, upImg },
	data() {
		return {
			show: false,
			loading: false,
			organList: apiUtil.getOrganList(),
			sexList: ['未设置', '男', '女'],
			marriageList: ['未设置', '未婚', '已婚', '离异'],
			politicsStatusList: ['群众', '团员', '预备党员', '党员'],
			rankList: ['无', '助理工程师', '工程师', '高级工程师'],
			staffTypeList: ['未设置', '合同工', '劳务工'],
			educationList: ['未设置', '小学', '初中', '高中', '中专', '大专', '大本', '硕士', '博士', '博士后'],
			formData: {
				organId: apiUtil.getOrganId(''),
				depId: 0,
				staffId: 0,
				name: '',
				birthday: '',
				sex: '未设置',
				photo: '',
				nation: '',
				idCardNumber: '',
				marriage: '未设置',
				politicsStatus: '群众',
				orgion: '',
				registerdAddress: '',
				education: '未设置',
				rank: '无',
				schoolName: '',
				specialty: '',
				workDate: '',
				joinDate: '',
				staffType: '未设置',
				jobName: '',
				homeTel: '',
				phone: '',
				homeAddress: '',
				email: '',
				contractStart: '',
				contractEnd: '',
				officialDate: ''
			},
			formRule: {}
		};
	},
	methods: {
		...apiUtil,
		_show(form) {
			this.show = true;
			this.getCategoryList();
			this.$nextTick(() => {
				this.$refs.formRef.resetFields();
				if (form) {
					let formData = JSON.parse(JSON.stringify(form));
					this.formData = formData;
					this.formData.officialDate = moment(formData.officialDate, 'YYYY-MM-DD');
					this.formData.contractEnd = moment(formData.contractEnd, 'YYYY-MM-DD');
					this.formData.workDate = moment(formData.workDate, 'YYYY-MM-DD');
					this.formData.joinDate = moment(formData.joinDate, 'YYYY-MM-DD');
					this.formData.contractStart = moment(formData.contractStart, 'YYYY-MM-DD');
					this.formData.birthday = moment(formData.birthday, 'YYYY-MM-DD');
				} else {
					this.formData.organId = apiUtil.getOrganId('');
					this.formData.depId = 0;
					this.formData.staffId = 0;
					this.formData.name = '';
					this.formData.birthday = '';
					this.formData.sex = '未设置';
					this.formData.photo = '';
					this.formData.nation = '';
					this.formData.idCardNumber = '';
					this.formData.marriage = '未设置';
					this.formData.politicsStatus = '群众';
					this.formData.orgion = '';
					this.formData.registerdAddress = '';
					this.formData.education = '未设置';
					this.formData.rank = '无';
					this.formData.schoolName = '';
					this.formData.specialty = '';
					this.formData.workDate = '';
					this.formData.joinDate = '';
					this.formData.staffType = '未设置';
					this.formData.jobName = '';
					this.formData.homeTel = '';
					this.formData.phone = '';
					this.formData.homeAddress = '';
					this.formData.email = '';
					this.formData.contractStart = '';
					this.formData.contractEnd = '';
					this.formData.officialDate = '';
				}
			});
		},
		_close() {
			this.$refs.formRef.resetFields();
			this.show = false;
			this.$emit('close');
		},
		doSubmit() {
			this.$refs.formRef.validate(valid => {
				if (valid) {
					utils.showSpin();
					request.post('/platform/staff/info/save', this.formData).then(ret => {
						this.$refs.formRef.resetFields();
						// console.log(ret)
						this.show = false;
						if (ret.code == 200) {
							utils.success('操作成功！').then(() => {
								this.show = false;
								this.$emit('success');
							});
						}
					});
				}
			});
		},
		// 获取分类数据
		getCategoryList() {
			this.loading = true;
			this.http
				.get('/platform/study/category')
				.then(ret => {
					if (ret.code == 200) {
						this.categoryList = ret.data;
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		// 选择人员
		staff(e) {
			// console.log(e);
			this.formData.name = e[0].name;
			this.formData.staffId = e[0].staffId;
		}
	}
};
</script>

<style scoped>
.demo-drawer-footer {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
	background: #fff;
}
</style>
